import { Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';
import { Observable, catchError, iif, map, of, switchMap } from 'rxjs';
import { Blob } from 'activestorage';
import { getTime } from 'date-fns';
import { Response } from '@parashift/ngx-airbrush';
import { ActiveStorageAttachment, Batch, Document, DocumentGroup, DocumentTaskStatus, ListFilter, WorkflowStep, UrlVars } from '@parashift/shared/models';
import { BatchType, GroupByType } from '@parashift/shared/types';
import { ServiceDecorator } from '../decorators/service.decorator';
import { BaseApiService } from './../base';
import { QueryParamsService } from '../../query-params.service';
import { SessionService } from '../../session.service';
import { AUTHORIZATION, AuthorizationMethod } from '@parashift/shared/constants';
import { Endpoint } from '../../env.service';

@Injectable({
  providedIn: 'root'
})
@ServiceDecorator({
  model: () => Batch,
  endpointUrl: BatchType
})
export class BatchService extends BaseApiService<Batch> {
  className = BatchService;

  constructor(
    http: HttpClient,
    queryParamsService: QueryParamsService,
    private sessionService: SessionService
  ) {
    super(http, queryParamsService);
    this.baseUrl = Endpoint.individual_extraction;
    this.apiVersion = this.environment.endpoints.individual_extraction_version
  }

  // This method returns a single locked batch
  // If there is a batch_id we use /batches/:id/lock
  // If we ask for the next batch in the batch we can apply a listFilter and use /batches/validation/lock
  // This method should only be used by the locked-document-or-batch-register!
  // It then takes care of renewing the lock_timeout, unlocking and preloading the next batch
  findAndLockRecord(tenant_id: number, batch_id?: string, listFilter?: ListFilter): Observable<Batch> {
    const params = listFilter ? this.queryParamsService.getParams(listFilter, true) : undefined;
    const urlVars: UrlVars = { postfix: 'lock' + ( params ? '?' + params : '')};
    urlVars.after_endpoint_url = batch_id ?  undefined : 'validation';

    this.setProperties(tenant_id, urlVars);
    const url = this.buildUrl(batch_id);
    const httpCall = this.http.put(url, {}, { observe: 'response' }) as Observable<Response>;

    return httpCall.pipe(
      map((res: Response) => this.successful(res)),
      map((res: Response) => this.extract(res) as Batch),
      catchError((res: any) => this.handleError(res))
    );
  }

  isRecordLocked(batch: Batch): boolean {
    if (batch.lock_timeout && batch.lock_user_id) {
      const now = getTime(new Date());
      const current_lock_timeout = getTime(batch.lock_timeout);
      return current_lock_timeout >= now && batch.lock_user_id === this.sessionService.user_id
             ? false
             : (current_lock_timeout > now) ? true : false;
    }
    return false;
  }

  isRecordLockedByUser(batch: Batch): boolean {
    if (batch.lock_timeout && batch.lock_user_id) {
      const now = getTime(new Date());
      const current_lock_timeout = getTime(batch.lock_timeout);
      return current_lock_timeout >= now && batch.lock_user_id === this.sessionService.user_id
             ? true
             : false;
    }

    return false;
  }

  isLoading(batch: Batch): boolean {
    return batch.id.includes('loading');
  }

  createFromDirectUpload(fileHandler: Blob,
                         tenant_id: number,
                         initialModel: Partial<Batch['plainModel']> | Partial<Document['plainModel']>,
                         isBatch: boolean) {
    this.setProperties(tenant_id);

    const batch = new Batch({});
    batch.input_files = [
      {
        blob_signed_id: fileHandler['signed_id'],
        name: 'input_files'
      } as ActiveStorageAttachment
    ];

    if (isBatch) {
      if (initialModel) {
        batch.plainModel = initialModel as Batch['plainModel'];
      }
    } else {
      const document = new Document({});
      document.plainModel = initialModel as Document['plainModel'];
      document.tenant_id = tenant_id;

      batch.name = initialModel?.name || '';
      batch.classification_scope = initialModel?.classification_scope || null;
      batch.not_for_training = initialModel?.not_for_training || false;
      batch.validation_profile = initialModel?.validation_profile || '';
      batch.validation_required = initialModel?.validation_required || false;
      batch.documents = [document];
    }

    return this.saveRecord(batch, tenant_id);
  }

  resetDocument(batch: Batch) {
    const urlVars = {
      postfix: 'reset'
    };

    return this.findRecord(batch.id, undefined, batch.tenant_id, urlVars);
  }

  setValidationStepStatus(status: DocumentTaskStatus, batch: Batch) {
    const currentTask = batch.getCurrentTask();

    if (currentTask) {
      currentTask.status = status;
    }
  }

  setNextTask(documentId: string, currentTaskId: string, tenantId: number, nextTaskId: string) {
    const urlVars = { postfix: documentId + '/document_tasks/' + currentTaskId + '/next' };

    this.setProperties(tenantId, urlVars);
    const url = this.buildUrl();
    return this.http.post(url, { next_document_task_id: nextTaskId });
  }

  setTaskToDone(step: WorkflowStep, documentId: string, currentTaskId: string, tenantId: number) {
    return this.setTaskStatus('done', step, documentId, currentTaskId, tenantId);
  }

  setTaskToFailed(step: WorkflowStep, documentId: string, currentTaskId: string, tenantId: number) {
    return this.setTaskStatus('fail', step, documentId, currentTaskId, tenantId);
  }

  private setTaskStatus(status: 'done' | 'force_done' | 'fail', step: WorkflowStep, documentId: string, currentTaskId: string, tenantId: number) {
    const urlVars = { postfix: documentId + '/document_tasks/' + currentTaskId + '/' + status };

    this.setProperties(tenantId, urlVars);
    const url = this.buildUrl();
    return this.http.post(url, { step });
  }

  exportDocuments(format: 'json' | 'csv' = 'json', listFilter: ListFilter, tenant_id?: number) {
    const filter = new ListFilter({
      extra_fields: listFilter.extra_fields,
      fields: listFilter.fields,
      filter: listFilter.filter,
      include: listFilter.include,
      sorting: listFilter.sorting
    });
    const params = this.queryParamsService.getParams(filter, true);
    const urlVars = { postfix: 'download' + ( params ? '?' + params : '') };

    this.setProperties(tenant_id, urlVars);
    const url = this.buildUrl();

    return this.http.get(url, { params: { format }});
  }

  getPayload(id: string, api_key: string) {
    const listFilter = new ListFilter({ include: 'document_fields' });
    const params = this.queryParamsService.getParams(listFilter, true);
    const urlVars = { api_version: 'v2', postfix: id + ( params ? '?' + params : '') };
    this.setProperties(undefined, urlVars);
    const url = this.buildUrl();
    let returnedUrl = decodeURI(url);
    returnedUrl = returnedUrl.replace(/%2C/g, ',');
    const context = new HttpContext().set(AUTHORIZATION, { method: AuthorizationMethod.api_key, token: api_key })

    return this.http.get(url, { context }).pipe(
      map((res: any) => {
        return { data: res, url: returnedUrl };
      })
    );
  }

  saveRecordName(batchId: string, name: string, tenant_id: number, fullCheck = false): Observable<Batch> {
    const listFilter = new ListFilter({
      fields: { batches: 'editable,lock_timeout,lock_user_id' }
    });

    const updatedBatch = new Batch({
      id: batchId
    });
    updatedBatch.name = name;

    return this.findRecord(batchId, listFilter, tenant_id, { after_endpoint_url: 'validation' }).pipe(
      switchMap(batch => iif(() =>
        this.isRenamingAllowed(batch, fullCheck),
        this.saveRecord(updatedBatch, tenant_id, { after_endpoint_url: 'validation' }),
        of(null)
      ))
    );
  }

  proceed(document_id: string, tenant_id: number): Observable<void> {
    const urlVars: UrlVars = { postfix: 'proceed' };
    this.setProperties(tenant_id, urlVars);
    const url = this.buildUrl(document_id);

    return this.http.post<void>(url, {});
  }

  recordLockedOrUneditable(batch: Batch) {
    return this.isRecordLocked(batch)
      || this.isRecordLockedByUser(batch)
      || !batch.editable;
  }

  private isRenamingAllowed(batch: Batch, fullCheck: boolean) {
   return fullCheck
          ? !this.recordLockedOrUneditable(batch)
          : !this.isRecordLocked(batch);
  }

  groupBy(groupBy: GroupByType, batches: Batch[]): DocumentGroup[] {
    const groupActions = {
      name: () => this.groupByName(batches)
    };

    return groupBy && Array.isArray(batches) ? groupActions[groupBy]() : undefined;
  }

  private groupByName(batches: Batch[]): DocumentGroup[] {
    const groups: { [groupName: string]: DocumentGroup } = {};
    const emptyGroup = {
      groupName: $localize `:@@common.empty:Empty`,
      records: [] as Batch[],
      collapsed: false
    };

    batches.forEach(batch => {
      const name = (batch.name || '').split('/');

      if (name.length > 1) {
        const batchName = name.splice(name.length - 1, 1);
        batch.shortened_name = batchName[0];
        const groupName = name.join('-') as any;

        if (groups[groupName]) {
          groups[groupName].records.push(batch);
        } else {
          groups[groupName] = {
            groupName: name.join('/'),
            records: [batch],
            collapsed: false
          };
        }
      } else {
        emptyGroup.records.push(batch);
      }
    });

    // const isNumber = (n) => {
    //   return !isNaN(parseFloat(n)) && isFinite(n);
    // };

    const sortedGroups = Object.keys(groups)
    .sort().map(key => groups[key]);
    // .sort((a, b) => {
    //   if (isNumber(a) && isNumber(b)) {
    //     return Number(a) - Number(b);
    //   } else if (isNumber(a)) {
    //     return -1;
    //   } else if (isNumber(b)) {
    //     return 1;
    //   } else {
    //     const x = a.toLowerCase();
    //     const y = b.toLowerCase();
    //     return x < y ? -1 : x > y ? 1 : 0;
    //   }
    // }).map(key => groups[key]);
    sortedGroups.unshift(emptyGroup);
    return sortedGroups;
  }
}
